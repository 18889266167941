var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"validationRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('EMAIL_ADDRESS'),"label-for":"id-email-address"}},[_c('validation-provider',{attrs:{"name":_vm.$t('EMAIL_ADDRESS'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-email-address","maxlength":"60","placeholder":_vm.$t('EMAIL_ADDRESS')},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ROLE'),"label-for":"id-role"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ROLE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-role","placeholder":_vm.$t('ROLE'),"label":"name","clearable":false,"options":_vm.roles},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('FIRST_NAME'),"label-for":"id-firstname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('FIRST_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-firstname","maxlength":"45","placeholder":_vm.$t('FIRST_NAME')},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('LAST_NAME'),"label-for":"id-lastname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('LAST_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-lastname","maxlength":"45","placeholder":_vm.$t('LAST_NAME')},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('BUSINESS_UNIT'),"label-for":"id-business-unit"}},[_c('b-form-input',{attrs:{"id":"id-business-unit","maxlength":"50","placeholder":_vm.$t('BUSINESS_UNIT')},model:{value:(_vm.user.businessUnit),callback:function ($$v) {_vm.$set(_vm.user, "businessUnit", $$v)},expression:"user.businessUnit"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('LANGUAGE'),"label-for":"id-language"}},[_c('validation-provider',{attrs:{"name":_vm.$t('LANGUAGE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-language","placeholder":_vm.$t('LANGUAGE'),"label":"name","clearable":false,"options":_vm.LANGUAGES},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY'),"label-for":"id-company"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-company","placeholder":_vm.$t('COMPANY'),"label":"name","clearable":false,"options":_vm.allCompanies},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('PHONE_NUMBER'),"label-for":"id-phonenumber"}},[_c('VuePhoneNumberInput',{attrs:{"default-country-code":_vm.getIsoCountryCode(_vm.user.countryCode+_vm.user.phoneNumber)},on:{"update":_vm.handlePhoneNumberInput},model:{value:(_vm.user.phoneNumber),callback:function ($$v) {_vm.$set(_vm.user, "phoneNumber", $$v)},expression:"user.phoneNumber"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"id-send_daily_report","name":"send_daily_report"},model:{value:(_vm.user.isSendDailyEmail),callback:function ($$v) {_vm.$set(_vm.user, "isSendDailyEmail", $$v)},expression:"user.isSendDailyEmail"}},[_vm._v(" "+_vm._s(_vm.$t('SEND_DAILY_REPORT'))+" ")])],1)],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.user.id ? _vm.$t('BUTTON.UPDATE') : _vm.$t('BUTTON.ADD'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.RESET'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }